export const gateNames = {
  chartingReminders: 'fg_charting_reminders',
  docAi530Verification: 'doc_ai_530_verification',
  displayJobCancelButtonAtPageTop: 'bring_cancel_button_to_top_of_job_details_page',
  earningsRefresh: 'pro_earnings_refresh',
  earningsShiftBreakdown: 'pro_earnings_shift_hours_breakdown',
  findJobsInfiniteScroll: 'find_jobs_infinite_scroll',
  inAppDisputeResolution: 'pro_in_app_dispute_resolution',
  irpOrAgencyPro: 'fg_mobile_agency_irp_pros',
  proCardRedesign: 'pro_job_card_redesign',
  proClockOutSupervisorSignature: 'pro_clock_out_supervisor_signature',
  proClockOutSupervisorSignatureOT: 'pro_clock_out_supervisor_signature_ot',
  proGeolocationAdjustments: 'fg_pro_geolocation_adjustments',
  proGraphqlTransformations: 'pro_graphql_transformations',
  proPool: 'fg_pro_pool',
  proShiftConfirmationExperience: 'lx_ns_check_in_experience',
  proStoreReviewPrompting: 'fg_mobile_store_review_prompting',
  proStripeCompanyRep: 'pro_stripe_company_rep',
  proTempTimesheetsEnableAllShiftTypes: 'fg_mobile_timesheets',
  pushNotifications: 'fg_mobile_push_notifications',
  roundClockOut: 'pro_round_clock_out',
  showMileageInJobDetails: 'show_mileage_in_job_details',
  splitPay: 'split_pay',
  termsOfService: 'terms_of_service',
  topLevelBookButton: 'top_level_book_button',
  travelQualificationsImprovements: 'pro_travel_qualifications_improvements',
  qualificationAutomationEnabled: 'credential_automation_ui',
  xrTransparency: 'pro_xr_transparency',
  taxUiUplift: 'pro_tax_ui_uplift',
  selfServiceFg: 'feature_self_service',
};

export const experimentNames = {
  ahaEcode: {
    name: 'aha_ecode',
  },
  preScreeningXP: {
    name: 'xp_prescreening',
    parameters: {
      applicationVersion: 'application_version',
    },
  },
};
