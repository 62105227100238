import {
  Box,
  Heading,
  HStack,
  Icon,
  Label,
  ListOptionType,
  MultiSelectField,
  SubmitButton,
  Text,
  VStack,
} from '@medely/ui-kit';
import { FixedFooterLayout } from '@mp/components/FixedFooterLayout';
import React from 'react';
import { UnsavedChangesDialog } from './UnsavedChangesDialog';
import { useFormContext } from 'react-hook-form';
import { hasChangesOnForm } from '../../utils';
import useDialog from '@mp/hooks/useDialog';

type SpecialtiesFormProps = {
  positionName: string | null | undefined;
  onBackClick: (params: undefined) => void;
  isSpecialtiesLoading: boolean;
  options: ListOptionType[] | undefined;
  preSelectedSpecialties: number[] | undefined;
};

export const SpecialtiesForm = ({
  onBackClick,
  positionName,
  isSpecialtiesLoading,
  options,
  preSelectedSpecialties,
}: SpecialtiesFormProps) => {
  const { open, openDialog, closeDialog } = useDialog();
  const { watch } = useFormContext();

  const newSelectedSpecialties = watch('specialty_ids');

  const handleBackClick = () => {
    const hasUnsavedChanges = !hasChangesOnForm({
      preSelectedSpecialties,
      selectedSpecialties: newSelectedSpecialties,
    }).hasNoPayload;
    if (hasUnsavedChanges) {
      return openDialog();
    }
    onBackClick(undefined);
  };

  return (
    <>
      <FixedFooterLayout
        title={
          <>
            <Box onClick={() => handleBackClick()}>
              <HStack>
                <Icon
                  name="chevron-left"
                  color="state.teal.primary"
                  variant="custom"
                  iconSize="10px"
                />
                <Label size="m" color="state.teal.primary">
                  Positions and specialties
                </Label>
              </HStack>
            </Box>
            <Heading size="m" color="text.primary">
              {positionName}
            </Heading>
            <Box pt={1}>
              <Text size="m" color="text.secondary">
                Add any relevant specialties for this position. If any specialties have lapsed,
                remove them to ensure more accurate shift matches.
              </Text>
            </Box>
          </>
        }
        footerContent={
          <SubmitButton fullWidth disabled={isSpecialtiesLoading} loading={isSpecialtiesLoading}>
            Update
          </SubmitButton>
        }
      >
        <VStack gap={2} pt={15}>
          {!!options && (
            <MultiSelectField
              variant="list"
              options={options}
              label="Selected Positions"
              name="specialty_ids"
            />
          )}
        </VStack>
      </FixedFooterLayout>
      <UnsavedChangesDialog onClose={closeDialog} open={open} onDiscardChangesClick={onBackClick} />
    </>
  );
};
