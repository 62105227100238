import { Box, Button, Heading, HStack, Icon, Label, Link, Text, VStack } from '@medely/ui-kit';
import { FixedFooterLayout } from '../../FixedFooterLayout/index';
import React, { ReactElement } from 'react';
import useProPoolInviteMutations from './mutations';
import { useProRouteParams } from '../../../hooks/useProRouteParams';
import { WorkforceInviteImage } from '../../../index';

export const Invite = ({ header }: { header?: ReactElement }) => {
  const { facilityName, professionalQualificationId } =
    useProRouteParams<'ProPoolInvite'>('ProPoolInvite').params;

  const { acceptPoolInvite, rejectPoolInvite, isSubmittingAccept, isSubmittingReject } =
    useProPoolInviteMutations(facilityName || 'the facility');

  const handleAccept = () => {
    if (professionalQualificationId) {
      acceptPoolInvite(professionalQualificationId);
    }
  };

  const handleReject = () => {
    if (professionalQualificationId) {
      rejectPoolInvite(professionalQualificationId);
    }
  };

  const areButtonsDisabled =
    isSubmittingAccept || isSubmittingReject || !professionalQualificationId;

  return (
    <FixedFooterLayout
      footerContent={
        <HStack justifyContent="center" gap={1}>
          <Button
            variant="outlined"
            onClick={handleReject}
            loading={isSubmittingReject}
            disabled={areButtonsDisabled}
          >
            No thanks
          </Button>
          <Button onClick={handleAccept} loading={isSubmittingAccept} disabled={areButtonsDisabled}>
            I&apos;m interested
          </Button>
        </HStack>
      }
    >
      {header && <>{header}</>}
      <VStack alignItems="center">
        <VStack alignItems="center" maxWidth="343px">
          <Box mb={2}>
            <WorkforceInviteImage />
          </Box>
          <VStack alignItems="center" mb={4}>
            <Heading size="s">Congratulations!</Heading>
            <Text size="l">You&apos;re invited to join the Professional Pool at</Text>
            <HStack>
              <Text bold size="l">
                {facilityName}
              </Text>
              <Text size="l">!</Text>
            </HStack>
          </VStack>
          <VStack
            mb={2}
            px={1.5}
            py={2}
            gap={1.5}
            bgColor="surface.secondary"
            borderRadius="8px"
            width="343px"
          >
            <Label size="m">Benefits to joining a facilities pool</Label>
            <HStack gap={2} alignItems="center">
              <VStack>
                <Icon
                  variant="custom"
                  color="state.success.secondary"
                  name="circle-check"
                  boxSize="24px"
                />
              </VStack>
              <HStack flex={1}>
                <Text size="m">
                  Earn more with priority access to shifts paid at per diem market rates or higher.
                </Text>
              </HStack>
            </HStack>
            <HStack gap={2} alignItems="center">
              <VStack>
                <Icon
                  variant="custom"
                  color="state.success.secondary"
                  name="circle-check"
                  boxSize="24px"
                />
              </VStack>
              <HStack flex={1}>
                <Text size="m">Work consistently with the same facility.</Text>
              </HStack>
            </HStack>
            <HStack gap={2} alignItems="center">
              <VStack>
                <Icon
                  variant="custom"
                  color="state.success.secondary"
                  name="circle-check"
                  boxSize="24px"
                />
              </VStack>
              <HStack flex={1}>
                <Text size="m">
                  Remain flexible and continue to book shifts at any Medely location.
                </Text>
              </HStack>
            </HStack>
          </VStack>
          <Text size="m">
            *Note: Expressing interest does not guarantee pool placement. Placement depends on
            meeting further facility requirements.
          </Text>
          <HStack mt={5}>
            <Box pr={0.5}>
              <Text size="m">Have questions?</Text>
            </Box>
            <Link
              variant="text"
              size="m"
              url="https://help.medely.com/en/articles/10270319-what-are-professional-pools"
            >
              Learn more
            </Link>
            <Text size="m">.</Text>
          </HStack>
        </VStack>
      </VStack>
    </FixedFooterLayout>
  );
};
