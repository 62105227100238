import React from 'react';
import { IJob } from '@medely/types';
import { Box, Heading, Label, Text, HStack, Icon } from '@medely/ui-kit';
import { useGate } from 'statsig-react';
import { gateNames } from '@mp/constants/statsig';
import { useCurrentUser } from '@mp/hooks/useCurrentUser';
import { centsToCurrency } from '@mp/utils';
import { ChartingReminder, JobDetailsListItem, TimesheetReminder } from '@mp/components';
import ContactFacility from '@mp/components/contactFacility/ContactFacility';

type JobFacilityInstructionsProps = {
  job: IJob;
  smallIcon?: boolean;
};

export function JobFacilityInstructions({ job, smallIcon = false }: JobFacilityInstructionsProps) {
  const { isMarketplace } = useCurrentUser();
  const { value } = useGate(gateNames.chartingReminders);
  const chartingReminders = typeof value === 'boolean' ? value : false;

  const hasClinicalContactName =
    job.clinical_contact?.first_name && job.clinical_contact?.last_name;

  const hasScrubs = job.scrubs_provided;
  const isParkingFree = job.location?.parking_free;
  const isParkingReimbursed = job.location?.parking_reimbursed;
  const showParkingReimbursement = isParkingReimbursed && isMarketplace;
  const isParkingValidated = job.location?.parking_validated;
  const parkCost = centsToCurrency(job.location?.parking_cost_cents ?? 0);
  const parkReimbursed = centsToCurrency(job.location?.parking_reimbursement_cents ?? 0);
  const showTimesheetReminder = job.location?.require_upload_on_clock_out;

  const ParkingInfoItem = ({ text }: { text: string }) => (
    <HStack alignItems="center" ml={5}>
      <Icon
        name="check"
        iconSize={smallIcon ? '14px' : '20px'}
        color="text.primary"
        variant="custom"
      />
      <Box ml={smallIcon ? 0 : 1}>
        <Text size="m" color="text.secondary" testId="parking-info-item">
          {text}
        </Text>
      </Box>
    </HStack>
  );

  return (
    <Box p={smallIcon ? 2 : 0}>
      <Heading size="s" color="text.primary" testId="job-facility-instructions-title">
        Facility instructions
      </Heading>

      {hasClinicalContactName && (
        <>
          <Box mt={smallIcon ? 1 : 2}>
            <JobDetailsListItem
              icon={
                <Icon
                  name="light-hospital"
                  iconSize={smallIcon ? '14px' : '20px'}
                  color="text.primary"
                  variant="custom"
                />
              }
              titleNode={
                <Label size="l" color="text.primary" testId="icon-info-section-heading">
                  Clinical contact
                </Label>
              }
            >
              <Text size="m" color="text.secondary" testId="icon-info-section-secondary">
                The person you can contact when you arrive or if you have specific questions about
                the shift.
              </Text>
            </JobDetailsListItem>
          </Box>

          <Box ml={5} mt={smallIcon ? 1 : 2}>
            <Label
              size="m"
              color="text.primary"
              testId="job-facility-instructions-clinic-name-label"
            >
              Name
            </Label>
            <Text size="m" color="text.secondary" testId="job-facility-instructions-clinic-name">
              {job.clinical_contact?.first_name} {job.clinical_contact?.last_name}
            </Text>
          </Box>
        </>
      )}
      <Box py={1}>
        <ContactFacility contactableId={job.id} type="job" phone={job.facility_number} />
      </Box>
      {chartingReminders && (
        <>
          <Box mt={1}>
            <ChartingReminder smallIcon={smallIcon} />
          </Box>
          {showTimesheetReminder && (
            <Box mt={1}>
              <TimesheetReminder smallIcon={smallIcon} />
            </Box>
          )}
        </>
      )}

      <Box mt={smallIcon ? 1 : 3}>
        <JobDetailsListItem
          icon={
            <Icon
              name="light-parking-circle"
              iconSize={smallIcon ? '14px' : '20px'}
              color="text.primary"
              variant="custom"
            />
          }
          titleNode={
            <Label size="l" color="text.primary" testId="icon-info-section-heading">
              Parking
            </Label>
          }
        >
          <Text size="m" color="text.secondary" testId="icon-info-section-secondary">
            {job.location?.parking_instructions}
          </Text>
        </JobDetailsListItem>
      </Box>

      <Box>
        <ParkingInfoItem
          text={isParkingFree ? 'Free parking on premises' : `Parking cost (${parkCost})`}
        />
        {showParkingReimbursement && (
          <ParkingInfoItem text={`Parking reimbursed (${parkReimbursed})`} />
        )}
        {isParkingValidated && <ParkingInfoItem text="Parking validated" />}
      </Box>

      <Box mt={smallIcon ? 0 : 2}>
        <JobDetailsListItem
          icon={
            <Icon
              name="light-tshirt"
              iconSize={smallIcon ? '14px' : '20px'}
              color="text.primary"
              variant="custom"
            />
          }
          titleNode={
            <Label size="l" color="text.primary" testId="icon-info-section-heading">
              Scrubs
            </Label>
          }
        >
          <Text size="m" color="text.secondary" testId="icon-info-section-secondary">
            {`Scrubs are${!hasScrubs ? ' not' : ''} provided by the facility`}
          </Text>
        </JobDetailsListItem>
      </Box>
    </Box>
  );
}
