import { Box, Button, Divider, Drawer, Link, NavLink, styled } from '@medely/web-components';
import { useFirebaseContext, useConfigurationContext } from '@medely/ui-kit';
import {
  CalendarCheck,
  CalendarDay,
  CalendarLines,
  CircleDollar,
  CircleQuestion,
  CircleUser,
  ClipboardCheck,
  Clock,
  Gear,
  IdCard,
  Menu,
  RightFromBracket,
  Search,
  Share,
} from '@medely/web-components/icons';
import { assignmentOnboardingRoute, mobileSettingsRoutes, useRoutes, ONBOARDING } from 'routes';
import useCurrentUser from 'hooks/useCurrentUser';
import useDialog from 'hooks/useDialog';
import React from 'react';
import { useHistory } from 'react-router';
import useOnboarding from 'hooks/useOnboarding';
import { ReferralTag } from 'components/ReferralTag';
import { isAgencyAccount, isSaasAccount } from 'utils/account';
import { HideFrom } from '@medely/pro-multi-platform';
import { HELP_ARTICLE_URLS } from '@medely/base';

const ICONS = {
  Onboarding: ClipboardCheck,
  Today: CalendarCheck,
  'Find Jobs': Search,
  'My Shifts': CalendarDay,
  Timesheets: Clock,
  Earnings: CircleDollar,
  Credentials: IdCard,
  'My Assignments': CalendarLines,
  Profile: CircleUser,
  Settings: Gear,
};

const TopBarMobileNavigationLink = styled(NavLink)({ fontVariant: 'body1', py: 2, px: 0 });
const NavAction = styled(Link)(({ theme }) => ({
  all: 'initial',
  display: 'block',
  cursor: 'pointer',
  textDecoration: 'none',
  // @ts-ignore
  fontFamily: theme.typography.fontFamily,
  padding: '12px',
  // @ts-ignore
  fontSize: theme.typography.fontSize,
  lineHeight: '24px',
  color: theme.palette.grey[900],
  fontVariant: 'body1',
}));

const TopBarMobileNavigation = (): React.ReactElement => {
  const { help } = useConfigurationContext();
  const dialog = useDialog();
  const history = useHistory();
  const { assignmentsMissingConditions, isAssignmentOnboardingRequired, showOnboarding } =
    useOnboarding();
  const { currentUser, isApplicant, isDeactivated } = useCurrentUser();
  const { mobile: mobileRoutes } = useRoutes();
  const isSaasProfessional = isSaasAccount(currentUser);
  const isAgencyProfessional = isAgencyAccount(currentUser);
  const isMarketplaceApplicant = isApplicant && !isSaasProfessional && !isAgencyProfessional;
  const { logout } = useFirebaseContext();
  const helpUrl = isAgencyProfessional ? HELP_ARTICLE_URLS.PARTNERS_HELP_CENTER : help.url;
  const handleNavigation = (path: string) => {
    history.push(path);
    dialog.closeDialog();
  };
  const handleLogout = () => {
    dialog.closeDialog();
    logout();
  };

  const RouteButton = ({ route }): React.ReactElement => {
    const Icon = ICONS[route.name];

    return (
      <Box display="flex" alignItems="center">
        <Icon />
        <TopBarMobileNavigationLink
          key={route.name}
          to={route.path}
          exact={route.exact}
          onClick={() => handleNavigation(route.path)}
        >
          {route.name}
        </TopBarMobileNavigationLink>
      </Box>
    );
  };

  return (
    <>
      <Button sx={{ mr: 1 }} onClick={dialog.openDialog}>
        <Menu />
      </Button>
      <Drawer open={dialog.open} onClose={dialog.closeDialog}>
        <Box py={2} px={3} fontWeight={600}>
          Menu
        </Box>
        <Box sx={{ width: 320 }}>
          {!isMarketplaceApplicant && (
            <>
              {(showOnboarding || isAssignmentOnboardingRequired) && !isDeactivated && (
                <Box px={3}>
                  <RouteButton
                    route={
                      isAssignmentOnboardingRequired
                        ? assignmentOnboardingRoute(assignmentsMissingConditions[0])
                        : ONBOARDING
                    }
                    key="onboarding"
                  />
                </Box>
              )}

              <Box px={3}>
                {mobileRoutes.map((route) => (
                  <RouteButton route={route} key={route.name} />
                ))}
              </Box>
              <Box px={3}>
                {mobileSettingsRoutes.map((route) => (
                  <RouteButton route={route} key={route.name} />
                ))}
              </Box>
              <Divider />
            </>
          )}
          <Box py={2} px={3}>
            {!isDeactivated && !isSaasProfessional && (
              <Box position="relative" display="flex" alignItems="center">
                <Share />
                <NavAction>Refer a Friend</NavAction>
                <ReferralTag id="global_header" />
              </Box>
            )}
            <HideFrom saas>
              <Box display="flex" alignItems="center">
                <CircleQuestion />
                <NavAction external href={helpUrl}>
                  Help
                </NavAction>
              </Box>
            </HideFrom>
            <Box display="flex" alignItems="center">
              <RightFromBracket />
              <NavAction onClick={handleLogout}>Log out</NavAction>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default TopBarMobileNavigation;
